.base__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  gap: 16px;
}

.contained__image {
  max-width: 100%;
  width:100%;
}

.final__image {
  max-width: 100%;
  height:60%;
}

.preset__container {
  height: calc(100% - 74px);
  padding: 16px;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-align: center;
  overflow-y: auto;
}

.presets {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.preset__selection {
  position: relative;
  background-color: var(--white);
  border: 2px solid var(--ink-10);
  border-radius: 8px;
}

.preset__selected {
  background-color: var(--tp-light-green);
  border: 2px solid var(--tp-purple);
  border-radius: 8px;
}

.button__container {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px;
  background-color: #ffffff;
}

.checkedIcon {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 24px;
  height: 24px;
  filter: invert(52%) sepia(8%) saturate(2252%) hue-rotate(176deg)
    brightness(85%) contrast(96%);
}

.text__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  background-color: var(--white);
  border-radius: 16px;
}

.title {
  font: 500 normal 28px/36px Poppins;
  color: var(--tp-black);
}
.tag{
  font: 700 normal 16px/22px Poppins;
  color: var(--tp-purple);
}
.subtext {
  font: 500 normal 20px/26px Poppins;
  color: var(--tp-black);
}

.scratch__button {
  font: 700 normal 16px/22px Poppins;
  color: var(--tp-purple);
}

.body__text {
  font: 500 normal 18px/26px Poppins;
  text-align: center;
  margin-bottom: 8px;
  color: var(--tp-grey);
}

@media screen and (max-width: 480px) {
  .title {
    font: 500 normal 20px/26px Poppins;
    
  }
  .tag{
    font: 700 normal 16px/22px Poppins;
  }
  .subtext {
    font: 500 normal 16px/22px Poppins;
  }
  .subtext {
    font: 500 normal 16px/22px Poppins;
  }
  .scratch__button {
    font: 700 normal 16px/22px Poppins;
  }
  
  .body__text {
    font: 500 normal 14px/20px Inter;
    text-align: center;
    margin-bottom: 8px;
  }
}