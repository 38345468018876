.title {
  font: 500 normal 20px/26px Poppins;
  color: var(--tp-purple);
}

.question {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  text-align: center;
}

.base__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: #ECF2F5;
}

.avatar__builder{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #ECF2F5;
}