.modal__container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 16px;
  z-index: 999;
}

.error__modal__container {
  position: absolute;
  background-color: var(--tp-pink);
  font: 500 normal 14px/20px Poppins;
  text-align: center;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: calc(100% - 32px);
  padding: 16px;
  z-index: 1000;
  border-radius: 40px;
}


.modal__blackout {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #12142b69;
  z-index: 0;
}

.text__container {
  background: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 16px;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  gap: 24px;
  z-index: 2;
  font: 500 normal 16px/26px Poppins;
}

.button__container {
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}
