.base__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: #ecf2f5;
}

.title {
  font: 500 normal 28px/36px Poppins;
  
  color: var(--tp-black);
}

.body__text {
  font: 500 normal 20px/26px Inter;
  text-align: center;
  margin-bottom: 8px;
}

.answer__container {
  height: calc(100% - 74px);
  padding: 16px;
  position: absolute;
  top: 0;
  width: 100%;
  overflow-y: auto;
}

.question {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  text-align: center;
}

.input__container {
  background: none;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--tp-grey);
  padding: 4px 0px;
  width: 100%;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  font: 600 normal 20px/26px Poppins;
}

.input__container::placeholder {
  color: var(--ink-20);
}

.mcq__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  width: 100%;
  overflow-y: auto;
}

.button__container {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px;
  background-color: #ffffff;
}
@media screen and (max-width: 768px) {
  .input__container {
    font: 600 normal 16px/22px Poppins;
  }
}
