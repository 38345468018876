.avatar__display__container {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}
.contained__image{
  width: 100%;
}
.avatar__display {
  position: absolute;
  aspect-ratio: 1760 / 2480;
  z-index: 1;
  transition: transform 0.5s
}
.avatar__display__image{
  position: absolute;
  top: 0;
}
.full {
  top: 0;
  left: 50%;
  height: 100%;
  transform-origin: center center;
  transform: translateX(-50%) scale(1);
}
.bust {
  top: 0;
  left: 50%;
  width: 100%;
  transform-origin: top center;
  transform: translateX(-50%) scale(1.5);
}

.avatar__part {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.horizontal__container {
  position: relative;
  height: auto;
  width: 100%;
  background-color: var(--tp-orange);
  border-radius: 16px 16px 0px 0px;
  padding: 8px;
  z-index: 2;
}

.random__button{
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 5;
}

.slider__container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(11, calc(100% / 9));
  grid-template-rows: minmax(56px, 1fr);
  align-items: center;
  justify-items: flex-start;
  gap: 8px;
  overflow: auto;
  scroll-behavior: smooth !important;
}

.slider__container::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.category__container {
  background-color: var(--white);
  position: relative;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.active__category {
  border: 2px solid var(--tp-purple);
}
.required__category{
  background-color: var(--tp-pink);
  border: 2px solid #EC6D62;
}
.warning__icon{
  position: absolute;
  top: 0%;
  right: -25%;
  width: 20px;
  height: 20px;
}
.selection__container{
  width: 100%;
  height: 100%;
  max-height: max(217px,38%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  z-index: 4;
  overflow-y: auto;
  background-color: var(--white);
}

.selection__title{
  font: 500 normal 14px/20px Poppins
}

.selection__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  gap: 6.7px;
}

.selection {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  min-width: 52px;
  min-height: 52px;
  background-color: var(--paper);
  aspect-ratio: 1/1;
  border-radius: 5px;
}

.selection__selected{
  border: 2px solid var(--tp-purple);
  background-color: var(--ink-10);
}

.loadingPercent{
  font: 500 normal 14px/20px Poppins
}
