.button__style {
  text-transform: uppercase;
  padding: 9px 20px;
  border-radius: 30px;
  font: 600 normal 20px/30px Poppins;
  position: relative;
}

.button__style:hover {
  opacity: 0.8;
}

.fill__container {
  width: calc(100% - 4px);
}

.contained__button {
  background: var(--tp-purple);
  border: 1px solid transparent;
}

.black__text {
  color: var(--tp-black);
}

.white__text {
  color: var(--white);
}

.selected__button {
  background: var(--tp-black);
  border: 1px solid transparent;
  color: var(--white);
}

.outlined__button {
  background: none;
  border: 1px solid var(--tp-purple);
  color: var(--tp-purple);
}

.contained__button:disabled {
  background: var(--ink-10);
  border: 1px solid transparent;
}

.outlined__button:disabled {
  background: var(--ink-10);
  border: 1px solid transparent;
}

.selected__button:disabled {
  background: var(--ink-10);
  border: 1px solid transparent;
}

.checkedIcon {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  filter: invert(88%) sepia(7%) saturate(599%) hue-rotate(308deg)
    brightness(94%) contrast(100%);
}

.checkIcon {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  filter: invert(96%) sepia(0%) saturate(0%) hue-rotate(159deg) brightness(92%)
    contrast(83%);
}

.contained__red__button {
  background: var(--tp-pink);
  border: 1px solid transparent;
}

.outlined__red__button {
  background: none;
  border: 1px solid var(--tp-pink);
  color: var(--tp-pink);
}

.contained__red__button:disabled {
  background: var(--ink-10);
  border: 1px solid transparent;
  color: var(--tp-pink);
}

.outlined__red__button:disabled {
  background: var(--ink-10);
  border: 1px solid transparent;
}

@media screen and (min-width: 768px) {
  .button__style {
    font: 600 normal 16px/24px Poppins;
  }
}
