html,
body {
  padding: 0;
  margin: 0;
}
* {
  box-sizing: border-box;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 2px;             
}

::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: var(--ink-20);    /* color of the scroll thumb */
  border-radius: 2px;       /* roundness of the scroll thumb */
  border: 1px solid var(--ink-20);  /* creates padding around scroll thumb */
}

.base__mobile__container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.base__mobile__container:before{
  content: ' ';
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/images/Background/Desktop.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  opacity: 0.5;
}

.mobile__preview {
  position: relative;
  height: 90vh;
  width: calc(320 / 568 * 90vh);
  background-color: var(--paper);
  border-radius: 15px;
  overflow: hidden;
  border: 2px solid var(--ink);
}

.transparent_bg{
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .base__mobile__container:before {
    background-image: url(./assets/images/Background/Tablet.png);
  }
}



@media screen and (max-width: 480px) {
  .base__mobile__container:before {
    background-image: url(./assets/images/Background/Mobile.png);
  }
  .container {
    justify-content: space-evenly;
    background-color: var(--black);
  }
  .mobile__preview {
    height: calc(568 / 320 * 98vw);
    width: 98vw;
    border-radius: 10px;
  }
}


:root {
  --blue: #3278eb;
  --yellow: #fdec4f;
  --white: #ffffff;
  --paper: #f3f3f3;
  --peach: #f08d71;
  --ink-10: #e7e7e7;
  --ink-20: #cfcfcf;
  --ink-40: #b8b8b8;
  --ink-60: #707070;
  --ink-80: #404040;
  --tp-grey: #808191;
  --tp-green: #9BBE6A;
  --tp-pink: #F1D0D2;
  --tp-orange: #F9DAB3;
  --tp-light-green: #EEF1C2;
  --tp-yellow: #F6C855;
  --tp-purple: #5479AD;
  --tp-black: #12142b;
}


@font-face {
  font-family: "Inter";
  font-weight: 200;
  src: url(./assets/fonts/Inter/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: url(./assets/fonts/Inter/Inter-Light.ttf) format('truetype');
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url(./assets/fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url(./assets/fonts/Inter/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url(./assets/fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: url(./assets/fonts/Inter/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: "Inter";
  font-weight: 900;
  src: url(./assets/fonts/Inter/Inter-Black.ttf) format('truetype');
}


/*Poppins*/
@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: url(./assets/fonts/Poppins/Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: url(./assets/fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url(./assets/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url(./assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: url(./assets/fonts/Poppins/Poppins-Black.ttf) format('truetype');
}
